import { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion';

import Footer from '../components/Footer'
import RedirectOnKeyPress from './RedirectKey';
import Service from '../components/service';
import Curve from '../components/Curve';
import { useMediaQuery } from 'react-responsive';

const Services = () => {

  const isMobile = useMediaQuery({ maxWidth: "440px" });

  // -----Pre-Loader Hook-----
  const [isLoading, setIsLoading] = useState<boolean>(true);
  
  useEffect(() => {
    const loadContent = async () => {
      if (isMobile) {
        setTimeout(() => {
          setIsLoading(false);
          document.body.style.cursor = 'default';
          window.scrollTo(0, 0);
        }, 2000);
      } else {
        const LocomotiveScroll = (await import('locomotive-scroll')).default;
        const locomotiveScroll = new LocomotiveScroll();
        setTimeout(() => {
          setIsLoading(false);
          document.body.style.cursor = 'default';
          window.scrollTo(0, 0);
        }, 2000);
      }
    };

    loadContent();

  }, [isMobile]);

  return (
    <>
    <section>

      {/* -----Pre-Loader----- */}
      <AnimatePresence mode='wait'>
        {isLoading && <Curve />}
      </AnimatePresence>
        
        {/* -----Services----- */}
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 2.1}}
        >
        {/* ---Bg--- */}

        <div className="app">
            <Service />
        </div>


        {/* -----Footer----- */}
        <div className="footerish">
            <Footer />
        </div>
        </motion.div>

        {/* -----CTA Functionality----- */}
        <RedirectOnKeyPress />

    </section>
    </>
  )
}

export default Services;