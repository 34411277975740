import React from 'react';
import { Link } from 'react-router-dom';

import "./Header.scss";
import Logo from "../../assets/icons/Vector.svg";
import ctaicon from "../../assets/icons/cta.svg";
import { SmallHamburgerNav } from './SmallHamburgerNav';
import ToolTip from '../Tooltip';

const Header = () => {
    
    // -----Scroll to Top-----
    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }    

  return (
    <div className="header-wrapper">
        <div className="header-container">
            <div className="logo-box">
                <Link to="/">
                    <img src={Logo} alt="" />
                </Link>
            </div>
            <div className="header-navigations">
                <div className="navigations-link">
                    <Link onClick={scrollToTop} to="/about">About</Link>
                    <Link onClick={scrollToTop} to="/services">Services</Link>
                    <Link onClick={scrollToTop} to="/contact">Contact</Link>
                    <Link onClick={scrollToTop} to="/blog">Blog</Link>
                </div>
            </div>
            <div className="cta-icon">
                <Link to="https://calendly.com/aster-astriq/30min">
                    <ToolTip text='Press A to Initiate a meeting'>
                        <img src={ctaicon} alt="" />
                    </ToolTip>
                </Link>
            </div>
            <div className="hamburger-menu">
                <SmallHamburgerNav />
            </div>
        </div>
    </div>
  )
}

export default Header