import { motion } from "framer-motion";

const RibbonLogos = () => {
  return (
      <div className="flex overflow-hidden">
        <TranslateWrapper reverse>
          <LogoItemsBottom />
        </TranslateWrapper>
        <TranslateWrapper reverse>
          <LogoItemsBottom />
        </TranslateWrapper>
        <TranslateWrapper reverse>
          <LogoItemsBottom />
        </TranslateWrapper>
      </div>
  );
};

const TranslateWrapper = ({
  children,
  reverse,
}: {
  children: JSX.Element;
  reverse?: boolean;
}) => {
  return (
    <motion.div
      initial={{ translateX: reverse ? "-100%" : "0%" }}
      animate={{ translateX: reverse ? "0%" : "-100%" }}
      transition={{ duration: 40, repeat: Infinity, ease: "linear" }}
      className="flex px-2"
    >
      {children}
    </motion.div>
  );
};

const LogoItem = ({ name }: { name: string }) => {
  return (
    <a
      href="/free-evaluation"
      rel="nofollow"
      target="_blank"
      className="flex items-center justify-center gap-4 px-4 py-4 text-[#0d0603] transition-colors md:py-6"
    >
      <span className="whitespace-nowrap text-1xl md:text-1xl" style={{ fontFamily:"GeneralSans-Medium", letterSpacing: "-0.4px" }}>
        {name}
      </span>
    </a>
  );
};

const LogoItemsBottom = () => (
  <>
    <LogoItem name="You are just one step away to avoid Business Marketing Mistakes." />
    <LogoItem name="Click to get a Free Evaluation." />
  </>
);

export default RibbonLogos;