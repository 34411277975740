import React, { useRef } from 'react';
import { useScroll, motion, useTransform } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { HamburgerNav } from '../Header/HamburgerNav';
import { useMediaQuery } from 'react-responsive';

import "./Hero.scss";

import Header from '../Header';
import Vision from './components/Vision';
import HorizontalScroll from './components/HorizontalScroll';
import ContactHero from './components/ContactHero';
import { opacity } from '../Preloader/anim';

const Hero: React.FC = () => {


    // -----Scroll to Top-----
    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    // -----Hamburger Hooks (!if header & contact visible)-----
    const [refhead, inViewhead] = useInView({
        threshold: 0,
        delay: 100
    });
    const [refcontact, inViewcontact] = useInView({
        threshold: 0,
        delay: 100
    });
    
    // -----Parallax Effect for Cards-----
    const container = useRef(null);
    const { scrollYProgress } = useScroll({
        target: container,
        offset: ['start end', 'end start']
    })
    
    const isLargeTablet = useMediaQuery({ maxWidth: "825px" });
    const isLargeMobile = useMediaQuery({ maxWidth: "440px" });

    // -----Parallax Metric-----
    const sm = useTransform(scrollYProgress,
        [0, 1],
        isLargeMobile ? [0, 0] : [0, -30]
    );
    const md = useTransform(
        scrollYProgress,
        [0, 1],
        isLargeMobile ? [0, 0] : isLargeTablet ? [0, -30] : [0, 30]
    );


  return (
    <>
    <div className="hero-wrapper">
        <div className="hero-container">
          
          {/* ======HEADER====== */}
          <div className="header-hero">
            <Header />
          </div>


          {/* ======Hamburger Nav====== */}
          {!isLargeMobile && !inViewhead && !inViewcontact ? (
                <motion.div
                    className="hamburger-header fixed z-10 bg-gradient-to-br from-[#0d0603] to-[#1a1a1a] shadow-lg shadow-[#1a1a1a]"
                    variants={{
                        hidden: { display: 'none', opacity: 0 },
                        visible: { display: 'block', opacity: 1 },
                    }}
                    style={{ top: 16, right: 16 }}
                    initial="hidden"
                    animate="visible"
                    transition={{
                        duration: 0.7,
                        type: "spring",
                        mass: 3,
                        stiffness: 300,
                        damping: 70,
                    }}
                >
                    <HamburgerNav />
                </motion.div>
            ) : (
                <motion.div
                    className="hamburger-header fixed z-10 bg-gradient-to-br from-[#0d0603] to-[#1a1a1a] shadow-lg shadow-[#1a1a1a]"
                    variants={{
                        hidden: { display: 'block', opacity: 1 },
                        visible: { display: 'none', opacity: 0 },
                    }}
                    style={{ top: 16, right: 16 }}
                    initial="hidden"
                    animate="visible"
                    transition={{
                        duration: 0.3,
                        type: "spring",
                        mass: 3,
                        stiffness: 300,
                        damping: 70,
                    }}
                >
                    <HamburgerNav />
                </motion.div>
            )}


          {/* ======HERO====== */}
          <div className="main-hero">
            <div className="main-hero-container">
                <div className="cta-text-box main-hero-element">
                    <div className="cta-text">
                    <span>Press A to Initiate a Meeting</span>
                    </div>
                </div>
                <div className="heading-box main-hero-element">
                    <span>Transforming Businesses with Innovative Marketing Solutions</span>
                </div>
                <div className="subheading-box main-hero-element" ref={refhead}>
                    <span>We offer comprehensive marketing services and web solutions to help businesses thrive in the digital landscape. With our expertise in Google Ads, Meta Ads, website development, and more, we deliver results that drive success.</span>
                </div>
                <div className="cta-button-box main-hero-element" ref={container}>
                    <a href="/free-evaluation" onClick={scrollToTop}>
                        <button>Free Evaluation</button>
                    </a>
                </div>
              </div>
          </div>

          {/* ======CARDS====== */}
            <div className="ThreeCards-container">
                <div className="cards-container">
              <motion.div style={{y: sm}} className="card card1">
                  <div className="top-card">
                      <div className="category">
                          <span>Marketing</span>
                      </div>
                      <div className="card-heading">
                          <span>Drive Results with Effective Marketing Strategies</span>
                      </div>
                      <div className="card-subheading">
                          <span>Reach your target audience and achieve your business goals.</span>
                      </div>
                  </div>
              </motion.div>

              <motion.div style={{y: md}} className="card card2">
                  <div className="top-card">
                      <div className="category">
                          <span>Web Design</span>
                      </div>
                      <div className="card-heading">
                          <span>Establishing a Strong and Robust Online Presence</span>
                      </div>
                      <div className="card-subheading">
                          <span>Reach your target audience and achieve your business goals.</span>
                      </div>
                  </div>
              </motion.div>

              <motion.div style={{y: sm}} className="card card3">
                  <div className="top-card">
                      <div className="category">
                          <span>SEO & Branding</span>
                      </div>
                      <div className="card-heading">
                          <span>Enhance Your Brand  with Branding and Advanced SEO</span>
                      </div>
                      <div className="card-subheading">
                          <span>Reach your target audience and achieve your business goals.</span>
                      </div>
                  </div>
              </motion.div>
              </div>
            </div>
          
          {/* ======VISION====== */}
          <Vision />

          {/* ======TESTIMONIAL====== */}
          <HorizontalScroll />
          
          {/* ======Contact====== */}
          <div className='contact-container' ref={refcontact}>
            <ContactHero />
          </div>
        </div>
    </div>
    </>
  )
}

export default Hero