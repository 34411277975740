import { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion';

import Footer from '../components/Footer';
import { Contact } from '../components/Contact';
import { useMediaQuery } from 'react-responsive';

const ContactPage = () => {
  
  const isMobile = useMediaQuery({ maxWidth: "440px" });
  
  // -----Smooth Locomotive Scroll-----
  useEffect(() => {
    const loadContent = async () => {
      if (isMobile) {
        setTimeout(() => {
          document.body.style.cursor = 'default';
          window.scrollTo(0, 0);
        }, 2000);
      } else {
        const LocomotiveScroll = (await import('locomotive-scroll')).default;
        const locomotiveScroll = new LocomotiveScroll();
        setTimeout(() => {
          document.body.style.cursor = 'default';
          window.scrollTo(0, 0);
        }, 2000);
      }
    };

    loadContent();

  }, [isMobile]);

  return (
    <>
    <section>

        {/* -----Contact----- */}
        <motion.div>


        <div className="app">
            <Contact />
        </div>


        {/* -----Footer----- */}
        <div className="footerish">
          <Footer />
        </div>

        </motion.div>
        {/* -----CTA Functionality----- */}
        {/* <RedirectOnKeyPress /> */}
    </section>
    </>
  )
}

export default ContactPage;