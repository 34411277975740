import { useEffect } from 'react'
import { motion } from 'framer-motion';

import Footer from '../components/Footer';
import { FreeEvaluation } from '../components/Contact/FreeEvaluation';
import { useMediaQuery } from 'react-responsive';

const EvaluationPage = () => {
  
  const isMobile = useMediaQuery({ maxWidth: "440px" });
  
  // -----Smooth Locomotive Scroll-----
  useEffect(() => {
    const loadContent = async () => {
      if (isMobile) {
        setTimeout(() => {
          document.body.style.cursor = 'default';
          window.scrollTo(0, 0);
        }, 2000);
      } else {
        const LocomotiveScroll = (await import('locomotive-scroll')).default;
        const locomotiveScroll = new LocomotiveScroll();
        setTimeout(() => {
          document.body.style.cursor = 'default';
          window.scrollTo(0, 0);
        }, 2000);
      }
    };

    loadContent();

  }, [isMobile]);

  return (
    <>
    <section>

        {/* -----Hero----- */}
        <motion.div>

        <div className="app">
            <FreeEvaluation />
        </div>


        {/* -----Footer----- */}
        <div className="footerish">
          <Footer />
        </div>

        </motion.div>
    </section>
    </>
  )
}

export default EvaluationPage;