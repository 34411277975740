import { useRef } from "react";
import { motion, useTransform, useScroll } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useMediaQuery } from "react-responsive";

import HeroTestimonial from "./HeroTestimonial";

import Anonymous from "../../../assets/images/Anonymous.png";
import Meta from "../../../assets/icons/meta.svg";

const HorizontalScroll = () => {
  
  
  const isLargeTablet = useMediaQuery({ maxWidth: "825px" });
  
  //  ======In View for Grid Background====== 
  const [ref, inView] = useInView(
    {
      threshold: 0,
      delay: 100,
    }
    );
    
    return (
      <div className="relative h-full w-full bg-[#f8eee5] background-plain">
            {!inView && (<motion.div className="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_24px] [mask-image:radial-gradient(ellipse_80%_50%_at_50%_0%,#000_70%,transparent_110%)] background-max"
            
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            />)}
          <motion.span ref={ref} className="hero-testimonial-heading">{isLargeTablet ? "Clientele":"See What Our Client Says"}</motion.span>
      <HorizontalScrollCarousel />
    </div>
  );
};

//  ======horizontal scroll carousel====== 
const HorizontalScrollCarousel = () => {
  
  const isSmallLaptop = useMediaQuery({ maxWidth: "1189px" });
  const isSmolLaptop = useMediaQuery({ maxWidth: "1024px" });
  const isSmallTablet = useMediaQuery({ maxWidth: "769px" });
  const isLargeMobile = useMediaQuery({ maxWidth: "440px" });
  const isLargesmolMobile = useMediaQuery({ maxWidth: "414px" });
  const isMobile = useMediaQuery({ maxWidth: "400px" });
  const isMediumMobile = useMediaQuery({ maxWidth: "360px" });
  const isSmallMobile = useMediaQuery({ maxWidth: "340px" });
  const isGalaxyFold = useMediaQuery({ maxWidth: "280px" });
  
  // ----targeting div----
  const targetRef = useRef<HTMLDivElement | null>(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });
  
  
  // ----x axis conventions - if else for different screen sizes----
  const x = useTransform(
    scrollYProgress,
    [0, 1],
    isGalaxyFold ? ["2%", "-68%"] : isSmallMobile ? ["2%", "-65%"] : isMediumMobile ? ["2%", "-64.5%"] : isMobile ? ["3%", "-64%"] : isLargesmolMobile ? ["3%", "-65%"] : isLargeMobile ? ["3%", "-68%"] : isSmallTablet ? ["4%", "-58%"] : isSmolLaptop ? ["2.3%", "-66%"] : isSmallLaptop ? ["2.5%", "-65%"] : ["3%", "-60%"]
  );
  
  return (
    <section ref={targetRef} className="relative h-[300dvh]">
      <div className="sticky top-0 flex h-screen items-center overflow-hidden">
        <motion.div style={{ x }} className="flex">
            <HeroTestimonial />
            <SecHeroTestimonial />
            <ThirdHeroTestimonial />
        </motion.div>
      </div>
    </section>
  );
};

export default HorizontalScroll;

//  ======sceond card====== 
const SecHeroTestimonial = () => {
    return (
      <>
      <div className="herotestimonial-wrapper">
  
          <div className="herotestimonial-container">
              <div className="herotestimonial-box">
                  <div className="image-box">
                      <img src={Anonymous} alt="" />
                  </div>
                  <div className="herotestimonial-description">
                      <div className="herotestimonial">
                          <span>Agency AstriQ's Facebook ads service was exceptional. Kumar Prateek Ranjan (Aster) is professional, delivers on promises, and boosted our sales significantly. Highly recommended.</span>
                      </div>
                      <div className="test-info">
                          <div className="testimonial-name">
                              <span className="name">
                              Anonymous
                              </span>
                              <span className="company-name">
                               New Delhi, IN
                              </span>
                          </div>
                          {/* Line */}
                          <svg xmlns="http://www.w3.org/2000/svg" width="2" height="56" viewBox="0 0 2 56" fill="none">
                              <path d="M1 1L0.999998 55" stroke="black" stroke-width="2" stroke-linecap="round"/>
                          </svg>
                          {/* CompanyLogo */}
                          <img src={Meta} alt="" />
                      </div>
                  </div>
              </div>
          </div>
      </div>
      </>
    )
  }

//  ======third card====== 
const ThirdHeroTestimonial = () => {
    return (
      <>
      <div className="herotestimonial-wrapper">
  
          <div className="herotestimonial-container">
              <div className="herotestimonial-box">
                  <div className="image-box">
                    <img src={Anonymous} alt="" />
                  </div>
                  <div className="herotestimonial-description">
                      <div className="herotestimonial">
                          <span>Choosing Agency AstriQ for Facebook ads was great. Kumar Prateek Ranjan (Aster) is professional and effective, helping us reach new heights and increase sales impressively.</span>
                      </div>
                      <div className="test-info">
                          <div className="testimonial-name">
                              <span className="name">
                                Anonymous
                              </span>
                              <span className="company-name">
                                   New Jersey, USA
                              </span>
                          </div>
                          {/* Line */}
                          <svg xmlns="http://www.w3.org/2000/svg" width="2" height="56" viewBox="0 0 2 56" fill="none">
                              <path d="M1 1L0.999998 55" stroke="black" stroke-width="2" stroke-linecap="round"/>
                          </svg>
                          {/* CompanyLogo */}
                          <img src={Meta} alt="" />
                      </div>
                  </div>
              </div>
          </div>
      </div>
      </>
    )
  }