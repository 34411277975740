import React from 'react';
import "./Blogs.scss"
import comingsoon from "../../assets/images/BlogsComingSoon.png";
import { Link } from 'react-router-dom';

const Blogs = () => {
  return (
    <div className="blog-wrapper">
        <div className="absolute inset-0 -z-10 h-full w-full bg-[##f8eee5] [background:radial-gradient(125%_125%_at_50%_10%,##f8eee5_40%,#353436_100%)]"></div>
        <div className="blog-container">
            <div className="mainblog-container">
                <Link to='/'>
                    <img src={comingsoon} alt="" />
                </Link>
            </div>
        </div>
    </div>
  )
}

export default Blogs