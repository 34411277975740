import { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion';

import Preloader from '../components/Preloader';
import Hero from '../components/Hero'
import Footer from '../components/Footer'
import RedirectOnKeyPress from './RedirectKey';
import { useMediaQuery } from 'react-responsive';

const Home = () => {

  const isMobile = useMediaQuery({ maxWidth: "440px" });

  // -----Pre-Loader Hook-----
  const [isLoading, setIsLoading] = useState<boolean>(true);
  
  useEffect(() => {
    const loadContent = async () => {
      if (isMobile) {
        setTimeout(() => {
          setIsLoading(false);
          document.body.style.cursor = 'default';
          window.scrollTo(0, 0);
        }, 2000);
      } else {
        const LocomotiveScroll = (await import('locomotive-scroll')).default;
        const locomotiveScroll = new LocomotiveScroll();
        setTimeout(() => {
          setIsLoading(false);
          document.body.style.cursor = 'default';
          window.scrollTo(0, 0);
        }, 2000);
      }
    };

    loadContent();

  }, [isMobile]);

  return (
    <>
    <section>
        {/* -----Pre-Loader----- */}
        <AnimatePresence mode='wait'>
          {isLoading && <Preloader />}
        </AnimatePresence>

        {/* -----Hero----- */}
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 2.5}}
          >
        {/* ---Bg--- */}
        <div className="absolute h-full w-full bg-[#f8eee5]"><div className="absolute bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:34px_50px] [mask-image:radial-gradient(ellipse_80%_60%_at_50%_0%,#000_70%,transparent_110%)]"></div></div>

        <div className="app">
            <Hero />
        </div>


        {/* -----Footer----- */}
        <div className="footerish">
            <Footer />
        </div>

        </motion.div>
        {/* -----CTA Functionality----- */}
        <RedirectOnKeyPress />
    </section>
    </>
  )
}

export default Home