import React, { ReactNode, useState } from "react";
import "./Tooltip.scss";

interface TooltipProps {
    text?: string;
    children: ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ text, children }): JSX.Element => {
    const [visibility, setVisibility] = useState(false);
    return (
        <div className="tooltip-wrapper" style={{transition: '1.2s ease'}}
        onMouseEnter={() => setVisibility(true)}
        onMouseLeave={() => setVisibility(false)}>
            {children}
            {visibility ? <div className="tooltip">{text}</div> : null}
        </div>
    )
}

export default Tooltip;


// import { FC, ReactNode, useRef } from "react";

// interface Props {
//   children: ReactNode;
//   tooltip?: string;
// }

// const ToolTip: FC<Props> = ({ children, tooltip }): JSX.Element => {
//   const tooltipRef = useRef<HTMLSpanElement>(null);
//   const container = useRef<HTMLDivElement>(null);

//   return (
//     <div
//       ref={container}
//       onMouseEnter={({ clientX }) => {
//         if (!tooltipRef.current || !container.current) return;
//         const { left } = container.current.getBoundingClientRect();

//         tooltipRef.current.style.left = clientX - left + "px";
//       }}
//       className="group relative inline-block"
//     >
//       {children}
//       {tooltip ? (
//         <span
//           ref={tooltipRef}
//           className="invisible group-hover:visible opacity-0 group-hover:opacity-100 transition bg-blue-500 text-white p-1 rounded absolute top-full mt-2 whitespace-nowrap"
//         >
//           {tooltip}
//         </span>
//       ) : null}
//     </div>
//   );
// };

// export default ToolTip;