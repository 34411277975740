import React, { useEffect, useRef, useState } from 'react';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';
import { AnimatePresence } from 'framer-motion';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

import "../Contact.scss";

import { Notification } from '../Components/Notification';
import Header from '../../Header';
import ContactRounded from '../Components/ContactRounded';
import Magnet from '../../common/Magnet';
import arrow from "../../../assets/icons/InvertedArrow.svg"

type NotificationType = {
  id: number;
  text: string;
};

export const FreeEvaluation = () => {

  const [currentTime, setCurrentTime] = useState<string>(moment().utcOffset("+05:30").format('hh:mm:ss A'));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format('hh:mm:ss A'));
    }, 1000); // Update every second
    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);
  

  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const form = useRef<HTMLFormElement>(null);
  const removeNotif = (id: number) => {
    setNotifications((pv) => pv.filter((n) => n.id !== id));
  };

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (form.current) {
      emailjs
        .sendForm('service_9piyys7', 'template_50aqqz3', form.current, {
          publicKey: 'QyBy0oDH2W3S0wJWt',
        })
        .then(
          (result: EmailJSResponseStatus) => {
            console.log('SUCCESS!', result.text);
            if (form.current) form.current.reset();
            setNotifications((pv) => [...pv, generateNotification("Evaluation Request Sent Successfully!")]);
          },
          (error) => {
            console.log('FAILED...', error.text);
            setNotifications((pv) => [...pv, generateNotification("Please mail us at aster@astriq.co")]);
            setNotifications((pv) => [...pv, generateNotification("Error occurred")]);
          }
        );
    }
  };

  return (
    <>
    <div className="contact-wrapper">
      <div className="contact-container">

        <div className="header-hero">
          <Header />
        </div>

        <div className="maincontact-container">

          <div className="contact-heading">
            <div className="heading">
              <p>Get a <span>Free Evaluation!</span></p>
            </div>
            <div className="sub-heading">
              Free Evaluation.
            </div>
          </div>

          <div className="form-and-socials">
            <div className="left-form">
              <form ref={form} onSubmit={sendEmail}>

                <div className="form-label-name formlabel">
                  <label>Name<span>*</span></label>
                  <input type="text" name="user_name" placeholder='Jack Bauer' required />
                </div>

                <div className="form-label-companyname formlabel">
                  <label>Company Name<span>*</span></label>
                  <input type="text" name="company_name" placeholder='Abc Private Limited' required />
                </div>

                <div className="form-label-email formlabel">
                  <label>Email<span>*</span></label>
                  <input type="email" name="user_email" placeholder='abc@gmail.com' required />
                </div>

                <div className="form-label-num formlabel">
                  <label>Number<span>*</span></label>
                  <input type="tel" name="user_num" placeholder='+1 00078xxx' required />
                </div>

                <div className="form-label-website formlabel">
                  <label>Website<span>*</span></label>
                  <input type="text" name="user_website" placeholder='example.com' required />
                </div>
                
                <div className="form-label-evertriedads formlabel">
                  <label>Have you ever tried advertising on Social Networks or Google?<span>*</span></label>
                  <input type="text" name="user_evertriedads" placeholder='Yep | Naah' required />
                </div>
                
                <div className="form-label-sm formlabel">
                  <label>Which Platform do you use for your Business?</label>
                  <input type="text" name="user_sm" placeholder='Facebook | Instagram | YouTube | Google' />
                </div>

                <ContactRounded>
                  <button type='submit' className='submit-button'>Submit</button>
                </ContactRounded>
              </form>
            </div>
            <div className="right-details">
              <div className="furtherenquiry">
                <div className="enq-heading">
                  For Contact
                </div>
                <Magnet>
                  <Link to="/contact">
                    <div className="button-talktous">
                      Contact Now
                      <img src={arrow} alt="" />
                    </div>
                  </Link>
                </Magnet>
                <a href="mailto:aster@astriq.co" rel='noreferrer' target='_blank' className='a-email'>aster@astriq.co</a>
              </div>
              <div className="collaboration">
                <div className="collab-heading">
                  For Collaboration
                </div>
                <a href="mailto:aster@astriq.co" rel='noreferrer' target='_blank' className='a-email'>aster@astriq.co</a>
                <a href="https://wa.me/+919905927277" rel='noreferrer' target='_blank' className='a-whatsapp'>Click to Whatsapp Now!</a>
              </div>
              <div className="socials">
                <div className="socials-heading">
                  Socials
                </div>
                <div className="social-links">
                  <a href="https://www.instagram.com/agencyastriq/" rel='noreferrer' target='_blank' className='a-ig'>Instagram</a><br />
                  <a href="https://www.linkedin.com/company/agencyastriq/" rel='noreferrer' target='_blank' className='a-in'>LinkedIn</a><br />
                  <a href="https://www.facebook.com/" rel='noreferrer' target='_blank' className='a-fb'>Facebook</a>
                </div>
              </div>
              <div className="location">
                <div className="location-heading">
                  Location
                </div>
                <div className="location-detail">
                  Pune, IN
                  <p className='time'>{currentTime}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="flex flex-col gap-1 w-72 fixed top-2 right-2 z-50 pointer-events-none">
        <AnimatePresence>
          {notifications.map((n) => (
            <Notification removeNotif={removeNotif} {...n} key={n.id} />
          ))}
        </AnimatePresence>
    </div>
    </>
  );
};

const generateNotification = (text: string): NotificationType => {
  return {
    id: Math.random(),
    text: text,
  };
};