import { SiFacebook, SiInstagram, SiLinkedin } from "react-icons/si";
import React, { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";

export const HamburgerNav: React.FC = () => {
  const [active, setActive] = useState(false);

  return (
    <>
      <HamburgerButton active={active} setActive={setActive} />
      <AnimatePresence>{active && <LinksOverlay />}</AnimatePresence>
    </>
  );
};

const LinksOverlay = () => {
  return (
    <nav className="fixed right-4 top-4 z-40 h-[calc(100dvh_-_32px)] w-[calc(100%_-_32px)] overflow-hidden">
      <Logo />
      <LinksContainer />
      <FooterCTAs />
    </nav>
  );
};

const LinksContainer = () => {
  return (
    <motion.div className="space-y-4 p-12 pl-4 md:pl-20">
      {LINKS.map((l, idx) => {
        return (
          <NavLink key={l.title} href={l.href} idx={idx}>
            {l.title}
          </NavLink>
        );
      })}
    </motion.div>
  );
};

const NavLink = ({
  children,
  href,
  idx,
}: {
  children: ReactNode;
  href: string;
  idx: number;
}) => {
  return (
    <motion.a
      initial={{ opacity: 0, y: -8 }}
      animate={{
        opacity: 1,
        y: 0,
        transition: {
          delay: 0.75 + idx * 0.125,
          duration: 0.5,
          ease: "easeInOut",
        },
      }}
      exit={{ opacity: 0, y: -8 }}
      href={href}
      className="block text-5xl text-[#f8eee5] transition-colors hover:text-[#fff9ef] md:text-7xl"
      style={{fontFamily: "GeneralSans-Semibold", letterSpacing: "-0.4px"}}
    >
      {children}
    </motion.a>
  );
};

const Logo = () => {
  return (
    <motion.a
      initial={{ opacity: 0, y: -12 }}
      animate={{
        opacity: 1,
        y: 0,
        transition: { delay: 0.5, duration: 0.5, ease: "easeInOut" },
      }}
      exit={{ opacity: 0, y: -12 }}
      href="#"
      className="grid h-20 w-20 place-content-center rounded-br-xl rounded-tl-xl bg-[#f8eee5] transition-colors hover:bg-[#ffffff]"
    >
    <svg width="50" height="39" viewBox="0 0 1080 1080" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M134.021 682.273C144.398 681.695 188.958 678.106 225.798 638.674C262.638 599.241 266.013 551.473 266.464 540.379C266.984 551.473 270.359 599.241 307.134 638.674C343.973 678.106 388.532 681.695 398.912 682.273C388.532 682.85 343.973 686.44 307.134 725.873C270.295 765.307 266.92 813.074 266.464 824.163C265.949 813.074 262.574 765.307 225.798 725.873C188.958 686.44 144.398 682.85 134.021 682.273Z" fill="#0d0603"/>
      <path d="M514.719 8.66418L332.335 506.887C339.273 488.887 382.684 465.584 410.036 506.887C410.036 506.887 562.243 965.411 561.776 962.933C582.409 1024.48 524.987 1067.57 505.307 1071.76H944.961C896.056 1058.36 876.949 1039.25 854.529 988.989L514.719 8.66418Z" fill="#0d0603"/>
    </svg>

    </motion.a>
  );
};

const HamburgerButton = ({
  active,
  setActive,
}: {
  active: boolean;
  setActive: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <>
      <motion.div
        initial={false}
        animate={active ? "open" : "closed"}
        variants={UNDERLAY_VARIANTS}
        style={{ top: 16, right: 16 }}
        className="fixed z-10 rounded-xl bg-gradient-to-br from-[#0d0603] to-[#1a1a1a] shadow-lg shadow-[#1a1a1a]"
      />

      <motion.button
        initial={false}
        animate={active ? "open" : "closed"}
        onClick={() => setActive((pv) => !pv)}
        className={`group fixed right-4 top-4 z-50 h-20 w-20 bg-white/0 transition-all hover:bg-white/20 ${
          active ? "rounded-bl-xl rounded-tr-xl" : "rounded-xl"
        }`}
      >
        <motion.span
          variants={HAMBURGER_VARIANTS.top}
          className="absolute block h-1 w-10 bg-white"
          style={{ y: "-50%", left: "50%", x: "-50%" }}
        />
        <motion.span
          variants={HAMBURGER_VARIANTS.middle}
          className="absolute block h-1 w-10 bg-white"
          style={{ left: "50%", x: "-50%", top: "50%", y: "-50%" }}
        />
        <motion.span
          variants={HAMBURGER_VARIANTS.bottom}
          className="absolute block h-1 w-5 bg-white"
          style={{ x: "-50%", y: "50%" }}
        />
      </motion.button>
    </>
  );
};

const FooterCTAs = () => {

    // -----Scroll to Top-----
    const scrollToTop = () => {
      window.scrollTo(0, 0)
    }

  return (
    <>
      <div className="absolute bottom-6 left-6 flex gap-4 md:flex-col">
        {SOCIAL_CTAS.map((l, idx) => {
          return (
            <motion.a
              key={idx}
              href={l.href}
              initial={{ opacity: 0, y: -8 }}
              animate={{
                opacity: 1,
                y: 0,
                transition: {
                  delay: 1 + idx * 0.125,
                  duration: 0.5,
                  ease: "easeInOut",
                },
              }}
              exit={{ opacity: 0, y: -8 }}
            >
              <l.Component className="text-xl text-[#f8eee5] transition-colors hover:text-[#ffffff]" />
            </motion.a>
          );
        })}
      </div>

      <Link onClick={scrollToTop} to="/contact">
      <motion.button
        initial={{ opacity: 0, y: 8 }}
        animate={{
          opacity: 1,
          y: 0,
          transition: {
            delay: 1.125,
            duration: 0.5,
            ease: "easeInOut",
          },
        }}
        exit={{ opacity: 0, y: 8 }}
        className="absolute bottom-2 right-2 flex items-center gap-2 rounded-full bg-[#f8eee5] px-3 py-3 text-4xl uppercase text-[#1a1a1a] transition-colors hover:bg-[#0d0603] hover:text-[#f8eee5] md:bottom-4 md:right-4 md:px-6 md:text-2xl"
      >
          <span className="hidden md:block">Talk to an Expert</span> <FiArrowRight />
      </motion.button>
      </Link>
    </>
  );
};

const LINKS = [
  {
    title: "Home",
    href: "/",
  },
  {
    title: "Services",
    href: "/services",
  },
  {
    title: "About Us",
    href: "/about",
  },
  {
    title: "Contact",
    href: "/contact",
  },
  {
    title: "Blog",
    href: "/blog",
  },
];

const SOCIAL_CTAS = [
  {
    Component: SiInstagram,
    href: "https://www.instagram.com/agencyastriq/",
  },
  {
    Component: SiLinkedin,
    href: "https://www.linkedin.com/company/agencyastriq/",
  },
  {
    Component: SiFacebook,
    href: "https://www.facebook.com/",
  },
];

const UNDERLAY_VARIANTS = {
  open: {
    width: "calc(100% - 32px)",
    height: "calc(100vh - 32px)",
    transition: { type: "spring", mass: 3, stiffness: 300, damping: 70 },
  },
  closed: {
    width: "80px",
    height: "80px",
    transition: {
      delay: 0.75,
      type: "spring",
      mass: 3,
      stiffness: 300,
      damping: 70,
    },
  },
};

const HAMBURGER_VARIANTS = {
  top: {
    open: {
      rotate: ["0deg", "0deg", "45deg"],
      top: ["35%", "50%", "50%"],
    },
    closed: {
      rotate: ["45deg", "0deg", "0deg"],
      top: ["50%", "50%", "35%"],
    },
  },
  middle: {
    open: {
      rotate: ["0deg", "0deg", "-45deg"],
    },
    closed: {
      rotate: ["-45deg", "0deg", "0deg"],
    },
  },
  bottom: {
    open: {
      rotate: ["0deg", "0deg", "45deg"],
      bottom: ["35%", "50%", "50%"],
      left: "50%",
    },
    closed: {
      rotate: ["45deg", "0deg", "0deg"],
      bottom: ["50%", "50%", "35%"],
      left: "calc(50% + 10px)",
    },
  },
};