import React from 'react';
import Header from '../../Header'
import arrow from "../../../assets/icons/InvertedArrow.svg";

import "./AboutUs.scss";
import aaabout from "../../../assets/aaabout.mp4";
import { Link } from 'react-router-dom';
import RibbonLogos from './components/Ribbon';
import Magnet from '../../common/Magnet';

const AboutUs = () => {

  // -----Scroll to Top-----
  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    <div className="aboutus-wrapper">
      <div className="aboutus-container">

        <div className="header-hero">
          <Header />
        </div>

        <div className="mainaboutus-container">
          <div className="aboutus-heading">
            <div className="heading">
              About Us
            </div>
            <div className="sub-heading">
              <Magnet>
                <Link onClick={scrollToTop} to="/contact">
                  <div className="button-talktous">
                    Talk to Us
                    <img src={arrow} alt="" />
                  </div>
                </Link>
              </Magnet>
              <div className="text">
                At AstriQ Agency, we’re not your average marketing agency. We’re more reliable than an ex who disappears when things get tough. Think of us as your loyal wingman. dedicated to helping you succeed every step of the way.
              </div>
            </div>
          </div>

          <div className="mainvideo-box">
            <div className="video-box">
              <video autoPlay muted loop>
                <source src={aaabout} type='video/mp4' />
              </video>
            </div>
          </div>

          <div className="countdown">
            <div className="projectscmpltd">
              <p>45+</p>
              <span>Projects Completed</span>
            </div>
            <div className="clientssatisfaction">
              <p>100%</p>
              <span>Client Satisfaction</span>
            </div>
            <div className="years">
              <p>3+</p>
              <span>Years Completed</span>
            </div>
          </div>
          <div className="ribbon">
            <RibbonLogos />
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default AboutUs