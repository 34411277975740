import { motion, useInView } from 'framer-motion';
import React, { useRef } from 'react'
import { slideUp, opacity } from '../../Vision/textanimation';

const Vision: React.FC = () => {

  const visionPhrase = "Our Vision since 2024 is to increase client revenue fourfold while reducing the cost per acquisition by half. We aim to achieve this through personalized strategies in paid advertisement and the seamless optimization of websites, ensuring maximum return on investment.";
  const vision = useRef(null);
  const isInView = useInView(vision)

  return (
    <>
    <div className="vision-container">
      <div className="vision-box">
          <div className="vision-heading">
              <div className="subheading">
              <motion.p variants={opacity} animate={isInView ? "open" : "closed"}>EMPOWERING BUSINESSES</motion.p>
              </div>
              <div className="heading">
              <span>Our Vision</span>
              </div>
          </div>
          <div className="vision-description" ref={vision}>
            <p>
              {
                  visionPhrase.split(" ").map( (word, index) => {
                      return <span key={index} className='mask'><motion.span variants={slideUp} custom={index} animate={isInView ? "open" : "closed"} key={index}>{word}</motion.span></span>
                  })
              }
            </p>
          </div>
      </div>
    </div>
    </>
  )
}

export default Vision