import { motion } from 'framer-motion';
import React, { useState } from 'react';
import Meta from "../../../assets/icons/meta.svg";
import anonymous from "../../../assets/images/Anonymous.png";

const HeroTestimonial = () => {

  return (
    <>
    <div className="herotestimonial-wrapper">

        <div className="herotestimonial-container">
            <div className="herotestimonial-box">
                <div className="image-box">
                    <img src={anonymous} alt="" />
                </div>
                <div className="herotestimonial-description">
                    <div className="herotestimonial">
                        <span>Agency AstriQ's Facebook ads exceeded our expectations. Kumar Prateek Ranjan (Aster) is highly professional, reliable, and significantly increased our sales. Absolutely recommend their services!</span>
                    </div>
                    <div className="test-info">
                        <div className="testimonial-name">
                            <span className="name">
                            Anonymous
                            </span>
                            <span className="company-name">
                                Hidden
                            </span>
                        </div>
                        {/* Line */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="2" height="56" viewBox="0 0 2 56" fill="none">
                            <path d="M1 1L0.999998 55" stroke="black" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                        {/* CompanyLogo */}
                        <img src={Meta} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default HeroTestimonial