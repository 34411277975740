import React from 'react';

import "./Footer.scss";
import Linkedin from "../../assets/icons/LinkedIn.svg";
import Instagram from "../../assets/icons/Instagram.svg";
import Facebook from "../../assets/icons/Facebook.svg";
import Phone from "../../assets/icons/Phone.svg";
import Logo from "../../assets/icons/Vector.svg";
import { Link } from 'react-router-dom';
import Magnet from '../common/Magnet';
import { useMediaQuery } from 'react-responsive';


const Footer: React.FC = () => {
    const isLargeMobile = useMediaQuery({ maxWidth: "440px" });

    // -----Scroll to Top-----
    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }
    
  return (
    <>
    <div className="footer-wrapper">
        <div className="footer-container">
            <div className="top-foot">
                <div className="navigation-foot">
                    <Link onClick={scrollToTop} to="/">Home</Link>
                    <Link onClick={scrollToTop} to="/about">About</Link>
                    <Link onClick={scrollToTop} to="/services">Services</Link>
                    <Link onClick={scrollToTop} to="/blog">Blog</Link>
                    <Link onClick={scrollToTop} to="/contact">Try AstriQ</Link>
                </div>
                <div className="talk-with-expert-foot">
                    <div className="talk-with-expert-foottop">
                        <div className="talk-with-expert-text">
                            <p>Talk with <br /><span>an expert at AstriQ</span></p> 
                        </div>
                        <div className="socialmedia">
                            <Magnet>
                            <a href="https://www.instagram.com/agencyastriq/" target='_blank' rel="noreferrer">
                                <img src={Instagram} alt="Instagram of Agency AstriQ" />
                            </a>
                            </Magnet>
                            <Magnet>
                            <a href="https://www.facebook.com/" target='_blank' rel="noreferrer">
                                <img src={Facebook} alt="Facebook of Agency AstriQ" />
                            </a>
                            </Magnet>
                            <Magnet>
                            <a href="https://www.linkedin.com/company/agencyastriq/" target='_blank' rel="noreferrer">
                                <img src={Linkedin} alt="Linkedin of Agency AstriQ" />
                            </a>
                            </Magnet>
                        </div>
                    </div>
                    <div className="talk-with-expert-footbottom">
                        <div className="contact-number">
                            <img src={Phone} alt="" />
                            <span>+91 9905927277</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-foot">
                <div className="bottom-left-combo">
                    <div className="bottom-foot-left">
                        <span>
                            &copy;2024 AstriQ
                        </span>
                    </div>
                    <div className="bottom-foot-middle">
                        <span>
                            Made with love for you
                        </span>
                    </div>
                </div>
                <div className="bottom-foot-right">
                    <div className="footer-logo">
                        <img src={Logo} alt="" />
                        <span>{isLargeMobile ? "AstriQ" : "Agency AstriQ"}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Footer