import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import preloaderlogo from "../../assets/icons/preloaderlogo.svg";
import "../Preloader/preloader.scss";
import { opacity, slideUp } from '../Preloader/anim';

export default function Curve(): JSX.Element {
    const [dimension, setDimension] = useState<{width: number, height: number}>({width: 0, height:0});
    const location = useLocation();

    useEffect(() => {
        setDimension({width: window.innerWidth, height: window.innerHeight});
    }, []);

    const wordsMap: { [key: string]: string } = {
        '/services': 'Services',
        '/about': 'About',
        '/contact': 'Contact',
        '/free-evaluation': 'Free Evaluation',
        '/blogs': 'Blogs',
    };

    const currentText = wordsMap[location.pathname] || '';

    const initialPath: string = `M0 0 L${dimension.width} 0 L${dimension.width} ${dimension.height} Q${dimension.width/2} ${dimension.height + 300} 0 ${dimension.height}  L0 0`;
    const targetPath: string = `M0 0 L${dimension.width} 0 L${dimension.width} ${dimension.height} Q${dimension.width/2} ${dimension.height} 0 ${dimension.height}  L0 0`;

    const curve = {
        initial: {
            d: initialPath,
            transition: {duration: 0.7, ease: [0.76, 0, 0.24, 1]}
        },
        exit: {
            d: targetPath,
            transition: {duration: 0.7, ease: [0.76, 0, 0.24, 1], delay: 0.3}
        }
    };

    return (
        <motion.div preloader-text variants={slideUp} initial="initial" exit="exit" className="introduction">
            {dimension.width > 0 && 
            <>
                <motion.p variants={opacity} initial="initial" animate="enter">
                    {currentText}
                </motion.p>
                <svg>
                    <motion.path variants={curve} initial="initial" exit="exit"></motion.path>
                </svg>
            </>
            }
        </motion.div>
    );
}

