import React, { useRef } from 'react';
import InvertedArrow from "../../../assets/icons/InvertedArrow.svg";
import MagnetContact from '../../common/MagneticContact';
import { Link } from 'react-router-dom';

import Rounded from '../../common/Rounded';
import { motion, useInView } from 'framer-motion';
import { slideUp } from '../../Vision/textanimation';


const ContactHero = () => {

    const GetInTouch = "Get in touch";
    const contact = useRef(null);
    const isInView = useInView(contact)

    // -----Scroll to Top-----
    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }
    
  return (
    <>
    <div className="contacthero-box">
        <div className="contacthero-container">
            <div className="contacthero-top">
                <div className="heading">
                    <div className="sub-sub-heading">
                        <span>Need an Unfair Advantage?</span>
                    </div>
                    <div className="sub-heading" ref={contact}>
                        <p>
                            {
                                GetInTouch.split(" ").map( (word, index) => {
                                    return <span key={index} className='mask'><motion.span variants={slideUp} custom={index} animate={isInView ? "open" : "closed"} key={index}>{word}</motion.span></span>
                                })
                            }
                        </p>
                    </div>
                </div>
                <div className="arrow">
                    <img src={InvertedArrow} alt="" />
                </div>
                <div className="contact-magnet">
                    <Link onClick={scrollToTop} to="/contact">
                        <MagnetContact />
                    </Link>
                </div>
            </div>
            <div className="contacthero-bottom">
                <div className="contacthero-info">

                    <div className="email">
                        <Link to="mailto:aster@astriq.co">
                            <Rounded>
                                <span>aster@astriq.co</span>
                            </Rounded>
                        </Link>
                    </div>
                    <div className="email">
                        <Link to="https://wa.me/+919905927277">
                            <Rounded>
                                <span>+91 9905927277</span>
                            </Rounded>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default ContactHero